<template>
  <div class="container">
    <h3 class="text-center mt-4">Майноры образовательных программ</h3>
    <div class="text-start mt-4 mb-3">
      <Button @click="create_modal=true">Добавить майнор образовательной программы</Button>
    </div>

    <DataTable :value="minorEducationProgram" :rows="10" :paginator="true" :responsive="true">
      <Column field="education_speciality_code" header="Код образовательной программы"></Column>
      <Column header="Название образовательной программы">
        <template #body={data}>
          <router-link :to="'/minor-formation-education-program?minor_education_program_id=' + data.id">{{data.education_speciality_name}}</router-link>
        </template>
      </Column>
      <Column field="uib_departments_name" header="Кафедра"></Column>
      <Column field="admission_year" header="Год"></Column>
      <Column field="study_level_name" header="Уровень обучения"></Column>
      <Column field="education_program_semester_type_name" header="Тип семестра"></Column>
      <Column field="language_name" header="Язык обучения"></Column>
      <Column header="Действие">
        <template #body={data}>
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-info p-mr-2"
                  @click="editMinor(data.id)"></Button>
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                  @click="confirmDeleteMinor(data.id)">
          </Button>
        </template>
      </Column>
    </DataTable>

    <Dialog v-model:visible="create_modal" modal header="Добавление" :style="{ width: '75vw' }">
      <div class="col-md-12 mt-4">
        <div class="form-row">
          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Кафедра</label>
            <div class="col-md-9">
              <select class="form-control form-select" v-model="data.department_id" disabled>
                <option v-for="(item, index) in educationdiscipline_form.departmentField"
                        :value="item.id"
                        :selected="item.id == getDepartmentId"
                        :key="index">{{ item.name_ru }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Набор</label>
            <div class="col-md-9">
              <select class="form-control form-select" name="admission_year" id="admission_year"
                      v-model="data.admission_year">
                <option selected>Выберите год набора</option>
                <option value="2017">2017</option>
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="create_studylevel" class="col-md-3 col-form-label">Уровень
              обучения</label>
            <div class="col-md-9">
              <select class="form-control form-select" id="create_studylevel"
                      v-model="data.study_level_id">
                <option v-for="(item, index) in educationprogram_form.studyLevel"
                        :value="item.id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="create_education_program_semester_type" class="col-md-3 col-form-label">Тип семестра</label>
            <div class="col-md-9">
              <select class="form-control form-select" id="create_education_program_semester_type"
                      v-model="data.education_program_semester_type_id">
                <option selected>Выберите тип семестра</option>
                <option v-for="(item, index) in educationprogram_form.educationProgramSemesterType"
                        :value="item.id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="create_language" class="col-md-3 col-form-label">Язык
              обучения</label>
            <div class="col-md-9">
              <select class="form-control form-select" id="create_language"
                      v-model="data.language_id">
                <option v-for="(item, index) in educationprogram_form.languages"
                        :value="item.id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="education_speciality_code" class="col-md-3 col-form-label">Код
              образовательной программы</label>
            <div class="col-md-9">
              <input id="education_speciality_code" type="text" class="form-control"
                     placeholder="Код образовательной программы"
                     v-model="data.education_speciality_code">
            </div>
          </div>
          <div class="form-group row mt-4">
            <label for="education_speciality_name" class="col-md-3 col-form-label">Название
              образовательной программы</label>
            <div class="col-md-9">
                      <textarea id="education_speciality_name" class="form-control"
                                placeholder="Название образовательной программы"
                                v-model="data.education_speciality_name"></textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
<!--            <label for="is_goso" class="col-md-3 col-form-label">-->
<!--              Является госо?-->
<!--            </label>-->
<!--            <div class="col-md-9">-->
<!--              <input id="is_goso" type="checkbox" class="big-checkbox" v-model="data.is_goso">-->
<!--            </div>-->

            <label for="minor_education_program_type_id" class="col-md-3 col-form-label">Тип майнора</label>
            <div class="col-md-9">
              <select class="form-control form-select" id="minor_education_program_type_id"
                      v-model="data.minor_education_program_type_id">
                <option v-for="(item, index) in minorEducationProgramType"
                        :value="item.id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary"
                  @click="submitData(data)">
            Создать
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="create_modal=false">
            Закрыть
          </button>
        </div>
      </div>
    </Dialog>

    <Dialog v-model:visible="edit_modal" modal header="Редактирование" :style="{ width: '75vw' }">
      <div class="col-md-12 mt-4">
        <div class="form-row">
          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Кафедра</label>
            <div class="col-md-9">
              <select class="form-control form-select" v-model="editedMinor.department_id" disabled>
                <option v-for="(item, index) in educationdiscipline_form.departmentField"
                        :value="item.id"
                        :selected="item.id == getDepartmentId"
                        :key="index">{{ item.name_ru }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Набор</label>
            <div class="col-md-9">
              <select class="form-control form-select" name="admission_year" id="admission_year"
                      v-model="editedMinor.admission_year">
                <option value="2017">2017</option>
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="create_studylevel" class="col-md-3 col-form-label">Уровень
              обучения</label>
            <div class="col-md-9">
              <select class="form-control form-select" id="create_studylevel"
                      v-model="editedMinor.study_level_id">
                <option v-for="(item, index) in educationprogram_form.studyLevel"
                        :value="item.id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="create_education_program_semester_type" class="col-md-3 col-form-label">Тип семестра</label>
            <div class="col-md-9">
              <select class="form-control form-select" id="create_education_program_semester_type"
                      v-model="editedMinor.education_program_semester_type_id">
                <option selected>Выберите тип семестра</option>
                <option v-for="(item, index) in educationprogram_form.educationProgramSemesterType"
                        :value="item.id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="create_language" class="col-md-3 col-form-label">Язык
              обучения</label>
            <div class="col-md-9">
              <select class="form-control form-select" id="create_language"
                      v-model="editedMinor.language_id">
                <option v-for="(item, index) in educationprogram_form.languages"
                        :value="item.id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="education_speciality_code" class="col-md-3 col-form-label">Код
              образовательной программы</label>
            <div class="col-md-9">
              <input id="education_speciality_code" type="text" class="form-control"
                     placeholder="Код образовательной программы"
                     v-model="editedMinor.education_speciality_code">
            </div>
          </div>
          <div class="form-group row mt-4">
            <label for="education_speciality_name" class="col-md-3 col-form-label">Название
              образовательной программы</label>
            <div class="col-md-9">
                      <textarea id="education_speciality_name" class="form-control"
                                placeholder="Название образовательной программы"
                                v-model="editedMinor.education_speciality_name"></textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="update_minor_education_program_type_id" class="col-md-3 col-form-label">Тип майнора</label>
            <div class="col-md-9">
              <select class="form-control form-select" id="update_minor_education_program_type_id"
                      v-model="editedMinor.minor_education_program_type_id">
                <option v-for="(item, index) in minorEducationProgramType"
                        :value="item.id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>
<!--            <label for="is_goso" class="col-md-3 col-form-label">-->
<!--              Является госо?-->
<!--            </label>-->
<!--            <div class="col-md-9">-->
<!--              <input id="is_goso" type="checkbox" class="big-checkbox" @change="changeIsGoso($event)" :checked="editedMinor.is_goso == '1'">-->
<!--            </div>-->
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary"
                  @click="confirmEditMinor">
            Изменить
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="edit_modal=false">
            Закрыть
          </button>
        </div>
      </div>
    </Dialog>

    <ConfirmDialog>
    </ConfirmDialog>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: "EducationProgramMiner",
  data() {
    return {
      create_modal: false,
      edit_modal: false,
      id_edit_minor: null,
      data: {
        education_speciality_code: '',
        education_speciality_name: '',
        study_level_id: '',
        language_id: '',
        admission_year: '',
        department_id: this.getDepartmentId(),
        education_program_semester_type_id: '',
        minor_education_program_type_id: null,
      },
      editedMinor: null
    }
  },
  computed: {
    ...mapState('educationprogram', ['educationprogram_form']),
    ...mapState('educationdiscipline', ['educationdiscipline_form']),
    ...mapState('minorEducationProgram', ['minorEducationProgram', 'minorEducationProgramType'])
  },
  methods: {
    ...mapActions('minorEducationProgram', ['POST_MINOR_EDUCATION_PROGRAM_CREATE', 'GET_MINOR_EDUCATION_PROGRAM',
      'DELETE_MINOR_EDUCATION_PROGRAM', 'PUT_MINOR_EDUCATION_PROGRAM', 'GET_MINOR_EDUCATION_PROGRAM_TYPE']),
    ...mapActions('educationdiscipline', ['GET_DEPARTMENT_NAMES']),
    ...mapActions('educationprogram', ['GET_STUDY_LEVEL_NAMES', 'GET_EDUCATION_PROGRAM_SEMESTER_TYPE']),

    // changeIsGoso(e) {
    //   const value = e.target.checked ? 1 : 0
    //   console.log(value, 'value')
    //   this.editedMinor['is_goso'] = value
    //
    //   console.log(this.editedMinor, 'this.editedMinor')
    // },
    async submitData(data) {
      let result = await this.POST_MINOR_EDUCATION_PROGRAM_CREATE(data)
      if (result.status === true) {
        this.$message({title: 'Успешно'})
        await this.GET_MINOR_EDUCATION_PROGRAM()
      }
      this.create_modal = false
    },

    getDepartmentId() {
      return getCookie('DEPARTMENT_ID')
    },

    editMinor(id) {
      this.edit_modal = true
      this.editedMinor = this.minorEducationProgram.filter(i => i.id === id)[0]
    },

    async confirmEditMinor() {
      let result = await this.PUT_MINOR_EDUCATION_PROGRAM(this.editedMinor)
      if (result.status === true) {
        await this.GET_MINOR_EDUCATION_PROGRAM()
        this.$message({title: 'Успешно!'})
      }
      this.edit_modal = false
    },

    confirmDeleteMinor(id) {
      this.$confirm.require({
        message: 'Вы уверены что хотите удалить майнор? Удалятся также привязанные дисциплины',
        header: 'Удаление майнора',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          let result = await this.DELETE_MINOR_EDUCATION_PROGRAM(id)
          if (result.status === true) {
            await this.GET_MINOR_EDUCATION_PROGRAM()
            this.$message({title: 'Успешно!'})
          }
        }
      });
    },
  },
  async mounted() {
    await this.GET_DEPARTMENT_NAMES();
    await this.GET_STUDY_LEVEL_NAMES();
    await this.GET_EDUCATION_PROGRAM_SEMESTER_TYPE();
    await this.GET_MINOR_EDUCATION_PROGRAM();
    await this.GET_MINOR_EDUCATION_PROGRAM_TYPE();
  }
}
</script>

<style scoped>
.big-checkbox {
  width: 20px;
  height: 20px;
}
</style>